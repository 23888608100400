body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Segoe UI';
    text-decoration: none;
    background-color: #303741; /* Defina a cor desejada para toda a tela */
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Segoe UI';
    text-decoration: none;
  }

@media (min-width: 1230px) {
    .login-container-app { 
        margin-top: -55px;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: flex-end;
        padding-right: 15%;
        align-items: center;
        background-image: url('../../../../public/dist/img/an_elementos-09.png');
        background-size: 950px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-color: #303741;
    }

    .form-container-1 {
        color: white;
        width: 525px;
        background-color: rgba(255, 255, 255, 0);
        display: flex;
        margin: 5px;
        flex-direction: column;
        gap: 20px;
        padding: 30px 20px;
        text-align: flex;
        border-radius: 10px;
        margin-right: 10vw;
        /* margin-left: -80%; */
        padding-left: 60px;
    }

    .form-container-2 {
        color: white;
        width: 330px;
        background-color: rgba(255, 255, 255, 0.137);
        display: flex;
        margin: 5px;
        flex-direction: column;
        gap: 20px;
        padding: 30px 20px;
        text-align: flex;
        border-radius: 10px;
        margin-left: auto;
    }
  }

@media (min-width: 1025px) and (max-width: 1229px) {
    .login-container-app { 
        margin-top: -55px;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: flex-end;
        padding-right: 15%;
        align-items: center;
        background-image: url('../../../../public/dist/img/an_elementos-09.png');
        background-size: 800px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-color: #303741;
    }

    .form-container-1 {
        color: white;
        width: 525px;
        max-width: 60%;
        background-color: rgba(255, 255, 255, 0);
        display: flex;
        margin: 5px;
        flex-direction: column;
        gap: 20px;
        padding: 30px 20px;
        text-align: flex;
        border-radius: 10px;
        margin-right: 2vw;
        /* margin-left: -80%; */
        padding-left: 60px;
    }

    .form-container-2 {
        color: white;
        width: 330px;
        max-width: 35%;

        background-color: rgba(255, 255, 255, 0.137);
        display: flex;
        margin: 5px;
        flex-direction: column;
        gap: 20px;
        padding: 30px 20px;
        text-align: flex;
        border-radius: 10px;
        margin-left: auto;
    }
  }

  @media (max-width: 1024px) {
    .login-container-app { 
        margin-top: -55px;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('../../../../public/dist/img/an_elementos-09.png');
        background-size: 500px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-color: #303741;
    }

    .form-container-1 {
        display: none;
    }

    .form-container-2 {
        color: white;
        width: 330px;
        background-color: rgba(255, 255, 255, 0.137);
        display: flex;
        margin: 5px;
        flex-direction: column;
        gap: 20px;
        padding: 30px 20px;
        text-align: flex;
        border-radius: 10px;
        position: 50% 50%;
    }
  }  

.input-group-app {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.input-group-app input {
    width: 100%;
}

.input-group-app-senha {
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.input-group-app-senha input {
    width: 100%;
}

.campo-app {
    border: solid 1.5px #9e9e9e;
    border-radius: 1rem;
    background: none;
    padding: 1rem;
    font-size: 1rem;
    color: #f5f5f5;
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.campo-app ~ label {
    position: absolute;
    left: 16px;
    color: #e8e8e8;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.campo-app:focus {
    outline: none;
    border: 1.5px solid #343a40;
}
.campo-app:focus ~ label {
    transform: translateY(-50%) scale(0.9);
    background-color: #50555a;
    padding: 0 0.2em;
    color: white;
}

.campo-app:disabled {
    cursor: not-allowed;
}

.btn-entrar {
    padding: 8px;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    background-color: #6c757d;
    color: white;
    cursor: pointer;
    font-size: 15px;
}

.btn-entrar:hover {
    border-color: #343a40;
    background-color: #3B4148;
}

.btn-entrar:active {
    background-color: #343a45;
}

.btn-entrar:disabled {
    color: #555455;
    background: #302e30;
    cursor: not-allowed;
}

.invalid-app ~ label{
    position: absolute;
    transform: translateY(-50%) scale(0.9);
    background-color: #51565f;
    padding: 0 0.2em;
    color: white;
    left: 27%;
    transform: translateX(29);
}

.invalid-border-app{
    border-color: #f1403d;
    animation: shake 0.5s ease-in-out;
    animation-iteration-count: 2;
}

.blank-app ~ label{
    color: white;
    left: 31%;
    transform: translateX(31);
}

.foco-app ~ label{
    position: absolute;
    transform: translateY(-50%) scale(0.9);
    background-color: #51565f;
    padding: 0 0.2em;
    color: white;
}

.muitas-tentativas-app ~ label {
    left: 14%;
    transform: translateX(15);
    border-color: #f1403d;
}

.aguarde-app ~ label {
    left: 24%;
    transform: translateX(25);
    border-color: #f1403d;
}

.clock-app {
    left: 40%;
    margin-top: -12px;
    position: relative;
    transform: scale(2);
    border-radius: 50%;
    border: 1px solid;
    width: 10px;
    height: 10px;
    color: white;
    background-color: white;
}

.clock-app::after {
    position: absolute;
    width: 0px;
    height: 4px;
    display: block;
    border-left: 1px solid #50555a;
    content: "";
    left: 4px;
    border-radius: 1px;
    top: 1px;
    animation-duration: 2s;
    transform-origin: bottom;
    animation-name: animation-minutes;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards; /* Mantém o estado final da animação */

}

.clock-app::before {
    position: absolute;
    width: 0px;
    height: 4px;
    display: block;
    border-left: 1px solid #50555a;
    content: "";
    left: 4px;
    border-radius: 1px;
    top: 1px;
}


@keyframes animation-minutes {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.time-app {
    margin-left: 50%;
    margin-top: -39px;
}

.icon-heading {
    display: flex;
    align-items: center;
    margin-top: -30px;
    /* Adicione mais estilos conforme necessário */
}

.text-portal {
    margin-top: -20px;
    color: #cccccd; /* Cor padrão do texto */
    font-size: 2.5rem;
    font-weight: 200;
}

.description-portal {
    margin-top: -30px;
    color: #aaaab3; /* Cor padrão do texto */
    font-size: 1.2rem;
    font-weight: 200;
}

.icon-img {
    margin-left: 25px;
    width: 350px; /* Defina o tamanho da imagem conforme necessário */
    height: 150px;
    /* Adicione mais estilos conforme necessário */
}

.wrapper-social {
    display: flex;
    justify-content: left;
    align-content: center;
}

.wrapper-social .icon {
    margin-top: -35px;
    margin-left: -15px;
    position: relative;
    border-radius: 50%;
    padding: 15px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
    cursor: pointer;
}

.href-social {
    color: white;
}

.href-social:hover {
    color: rgb(200, 193, 193);
}

.campo-app:focus ~ label,
.campo-app:-webkit-autofill ~ label {
    transform: translateY(-50%) scale(0.9);
    background-color: #50555a;
    padding: 0 0.2em;
    color: white;
}

/* Adicione o seguinte trecho de código */
.campo-app:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #50555a inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white;
}

.show-hide-login * {
    color: #9e9e9e;
}

.show-hide-login {
    position: absolute;
    right: 1vh;
    bottom: 12px;
}


.show-hide-login:hover {
    background-color: #9e9e9e76;
    border-radius: 50%;
    transition: 150ms;
}
