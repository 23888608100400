.login-container-adm { 
    margin-top: -55px;
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    background-image: url('../../../../../public/dist/img/an_elementos-10.png');
    background-size: 650px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #343a40;
  }

* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
    font-family: 'Segoe UI';
    text-decoration: none;
  }

.form-container-adm {
    color: white;
    width: 330px;
    background-color: rgba(255, 255, 255, 0.137);
    display: flex;
    margin: 5px;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px;
    text-align: center;
    border-radius: 10px;
}

.input-group-adm {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.input-group-adm input {
    width: 100%;
}
.campo-adm {
    border: solid 1.5px #9e9e9e;
    border-radius: 1rem;
    background: none;
    padding: 1rem;
    font-size: 1rem;
    color: #f5f5f5;
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.campo-adm ~ label {
    position: absolute;
    left: 16px;
    color: #e8e8e8;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.campo-adm:focus {
    outline: none;
    border: 1.5px solid #343a40;
}
.campo-adm:focus ~ label {
    transform: translateY(-50%) scale(0.9);
    background-color: #51565f;
    padding: 0 0.2em;
    color: white;
}

.campo-adm:disabled {
    cursor: not-allowed;
}

.btn-entrar {
    padding: 8px;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    background-color: #6c757d;
    color: white;
    cursor: pointer;
    font-size: 15px;
}

.btn-entrar:hover {
    border-color: #343a40;
    background-color: #3B4148;
}

.btn-entrar:active {
    background-color: #343a45;
}

.btn-entrar:disabled {
    color: #555455;
    background: #302e30;
    cursor: not-allowed;
}

.invalid-adm ~ label{
    position: absolute;
    transform: translateY(-50%) scale(0.9);
    background-color: #51565f;
    padding: 0 0.2em;
    color: white;
    left: 27%;
    transform: translateX(29);
}

.invalid-border-adm{
    border-color: #f1403d;
    animation: shake 0.5s ease-in-out;
    animation-iteration-count: 2;
}

.blank-adm ~ label{
    color: white;
    left: 31%;
    transform: translateX(31);
}

.foco-adm ~ label{
    position: absolute;
    transform: translateY(-50%) scale(0.9);
    background-color: #51565f;
    padding: 0 0.2em;
    color: white;
}

.muitas-tentativas-adm ~ label {
    left: 14%;
    transform: translateX(15);
    border-color: #f1403d;
}

.aguarde-adm ~ label {
    left: 24%;
    transform: translateX(25);
    border-color: #f1403d;
}

.clock-adm {
    left: 40%;
    margin-top: -12px;
    position: relative;
    transform: scale(2);
    border-radius: 50%;
    border: 1px solid;
    width: 10px;
    height: 10px;
    color: white;
    background-color: white;
}

.clock-adm::after {
    position: absolute;
    width: 0px;
    height: 4px;
    display: block;
    border-left: 1px solid #51565f;
    content: "";
    left: 4px;
    border-radius: 1px;
    top: 1px;
    animation-duration: 2s;
    transform-origin: bottom;
    animation-name: animation-minutes;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards; /* Mantém o estado final da animação */

}

.clock-adm::before {
    position: absolute;
    width: 0px;
    height: 4px;
    display: block;
    border-left: 1px solid #51565f;
    content: "";
    left: 4px;
    border-radius: 1px;
    top: 1px;
}


@keyframes animation-minutes {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.time-adm {
    margin-left: 4%;
    margin-top: -39px;
} 

.campo-adm:focus ~ label,
.campo-adm:-webkit-autofill ~ label {
    transform: translateY(-50%) scale(0.9);
    background-color: #51565f;
    padding: 0 0.2em;
    color: white;
}

/* Adicione o seguinte trecho de código */
.campo-adm:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #51565f inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white;
}