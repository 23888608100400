body {
    margin: 0;
    width: 100vw;
    height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  html, body {
    height: 100%;
  }
  
  .wrapper {
    padding-top: 55px;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }

  @keyframes trashIconMove {
    from {
    }
    to {
      transform: translateY(-5px); /* Movimenta o ícone para cima em 5 pixels */
    }
  }
  
  @keyframes eyeIconMove {
    from {
      transform: translateY(0) rotate(0deg); /* Iniciar a animação com o ícone deslocado na diagonal */
    }
    to {
      transform: translateY(-5px) rotate(30deg); /* Movimentar o ícone para a diagonal oposta */
    }
  }

  @keyframes penIconMove {
    from {
      transform: translateX(0) ; /* Iniciar a animação com o ícone deslocado na diagonal */
    }
    to {
      transform: translateX(5px) ; /* Movimentar o ícone para a diagonal oposta */
    }
  }

  .bg-gray{
    background-color: #343a40;
  }

  .bg-red{
    background-color: #EF5350;
  }

  .btn-gray{
    background-color: #6c757d;
    color: white
  }

  .btn-gray:hover{
    border-color: #343a40;
    background-color: #3B4148;
    color: white
  }

  #logout-button{
    margin-top: -7px;
  }

  #new-jur-button{
    margin-left: 5px;
  }

  .step{
    display: none
  }

  .step-control{
    box-shadow: rgba(0,0,0,0.1) 0px 1px 30px;
    margin: 0 5px;
  }

  #custom-radio input[type="radio"] {
    display: none;
  }
  #custom-radio input[type="radio"]:checked + label {
    background-color: #343a40;
    color: white;
  }
  #custom-radio label {
    display: inline-block;
    padding: 5px 5px;
    margin-left: 6px;
    background-color: #ECECEC;
    border-radius: 4px;
    cursor: pointer;
  }

  label.required::after {
    content: "*";
    color: red;
    margin-left: 3px;
  }

  .center-element {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .custom-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }


.background {
  background-color: #f4f6f9;
}

.action-column {
  width: 1%;
  white-space: nowrap;
}

.enviar:hover {
  border-color: #007bff;
  background-color: #007bff;
  color: white;
}

.add:hover {
  border-color: #28a745;
  background-color: #28a745;
}

.cancel:hover{
  border-color: #dc3545;
  background-color: #dc3545;
}

.icon-white{
  color: white;
}

.trash-animation:hover .fa-trash{
  animation: trashIconMove 0.5s alternate infinite; 
  /* Aplica a animação com duração de 0.5 segundos, se repetindo em loop */
}

.trash-animation:hover .fa-ban{
  animation: trashIconMove 0.5s alternate infinite; 
  /* Aplica a animação com duração de 0.5 segundos, se repetindo em loop */
}

.eye-animation:hover .fa-eye{
  animation: eyeIconMove 0.5s infinite alternate;
}

.pen-animation:hover .fa-pen{
  animation: penIconMove 0.5s infinite alternate;
}
 
.coluna-visualizar2{
  width: 85px; 
}

.text-midBold{
  font-weight: 600;
}

.green{
  color: #28a745;
}

.red {
  color: #c82333;
}

.erro{
  border-color: red;
  animation: shake 0.5s ease-in-out;
  animation-iteration-count: 2;
}
.erro::placeholder{
  color: #EF5350;
}

.erro-select {
  animation: shake 0.5s ease-in-out;
  animation-iteration-count: 2;
}

.erro-select .css-13cymwt-control{
  border-color: red;
}

.erro-select .css-13cymwt-control .css-1fdsijx-ValueContainer .css-1jqq78o-placeholder{
  color: #EF5350;
}

/* Estilos para a lista de sócios */
ul.list-group-flash {
  list-style: none; /* Remover os marcadores padrão da lista */
  padding: 0; /* Remover espaçamento interno da lista */
}

.list-group-flash li {
  padding: 10px; /* Adicionar espaçamento interno para cada item */
  border-bottom: 1px solid #dee2e6; /* Adicionar linha abaixo de cada item */
  transition: content 1s ease; /* Transição suave para a mudança de cor */
}

/* Defina o ícone do Flaticon quando estiver em hover usando ::before */
.list-group-flash li:hover i::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGMSURBVDhPY/j////u3bv7+vpevnwJZBMEDFu3bZeUkVdU1XRwcoGK4QVMt+/c5eDkYmBgePj46cePH4EMCFi5cqWNnYO4pLStvSOQDRVlYGCC0mDw9etXCAOoorCk/NmLV9y8/E+fvwSy4Xqwa5g8dTrEWggAsqdMmwFhM/38DlUEBG/fvoUw7ty5A2HAwe3btyEMJgUFBQgLCA4ePAhhqKioQBhwoKqqCmEwOTo6/v3zG8JZsXrdq1evgIzc7Mwf379BBIEAyAaKQDnAkErPyAQGKwTZOzh+/vwZKLhixQpgKAFFgCTQx+AgBQFGIAZGmZunz+cvUM9oa6iuWrmCk5MTwkUDIA1Aat++fQnJaSysbBBRZPDn9y95Odn42OiUlBQgl7mhoQFIKSoqMvz/e+rMObAaFMDEzAy0/PDR4+/fvXVwsIfaAAGJyakHDx+FcjDAr58/9uzchhJxBgYGUBY2wMbOsWvXLhQNIkICUBYOwMPDg+Kkf//+LV++/M27D1A+KuDh4khISAAAbW30PXpoOfEAAAAASUVORK5CYII=);
  font-family: "Font Awesome 5 Free"; /* Font Family do Font Awesome */
}

/* Estilizar o efeito de hover */
.list-group-flash li:hover {
  cursor: default;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.subitem {
  margin-left: 15px;
}

.image-input-preview {
  position: relative; /* Adicione isso para controlar o posicionamento do botão de remover */
}

.circle-placeholder {
  cursor: pointer;
  width: 175px;
  height: 175px;
  border: 2px dashed #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.input-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ccc;
}

/* Estilização específica para o ícone da câmera */
.input-indicator svg {
  font-size: 36px;
}

.image-container {
  position: relative;
  display: inline-block; /* Adicione isso para evitar que o botão de remover cause quebras de layout */
}

.remove-button {
  color: red;
  position: absolute;
  top: 5px; /* Ajuste a posição vertical conforme necessário */
  right: 5px; /* Ajuste a posição horizontal conforme necessário */
}

/* Esconder o campo de input real */
#imageInput {
  display: none;
}

/* cards do AppDashboard */
.background {
    display: block;
    padding: 20px 32px;
}
 
.articles {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    justify-content: flex-start;
    max-width: 1200px;
    gap: 24px;
}
 
.articles article {
    max-width: 200px;
    cursor: pointer;
    position: relative;
    display: block;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    border-radius: 16px;
}


.articles article h2 {
    margin: 0 0 18px 0;
    font-size: 1.6rem;
    color: var(--secondary);
    transition: color 0.3s ease-out;
}

.articles article p {
    margin: 0 0 18px 0;
    font-size: 1.0rem;
    color: var(--secondary);
    transition: color 0.3s ease-out;
}
  
  
.articles article img {
    max-width: 100%;
    transform-origin: center;
    transition: transform 0.4s ease-in-out;
}
  
.article-preview {
    margin-top: -25px;
    padding: 24px;
    background: white;
}

.articles figure {
    width: 100%;
    height: 150px;
    overflow: hidden;
}

.articles figure img {
    height: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    object-fit: cover;
}

.articles article:hover img {
    transform: scale(1.5); 
}

/* Estilos para o texto de aviso */
.small-text {
    font-size: 0.8rem; /* Tamanho de fonte menor */
    color: #cccccd; 
}

/* grid para criacao de usuarios*/
/* Define as áreas da grid */
.input-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 colunas de largura igual */
  grid-template-rows: repeat(3, auto); /* 3 linhas automáticas */
  gap: 10px; /* Espaço entre as células */
  grid-template-areas:
    'campo1 campo2 campo2 campo3'
    'campo1 campo4 campo5 campo6'
    'campo1 campo7 campo8 campo8'
    ;
}

/* Associa as classes às áreas */
.campo1 {
  grid-area: campo1;
  align-self: center;
  justify-self: center;
  margin-left: -10%;
}

.campo2 {
  grid-area: campo2;
}

.campo3 {
  grid-area: campo3;
}

.campo4 {
  grid-area: campo4;
}

.campo5 {
  grid-area: campo5;
}

.campo6 {
  grid-area: campo6;
}

.campo7 {
  grid-area: campo7;
  grid-column-start: 2;
  width: 152%;
}

.campo8 {
  grid-area: campo8;
  grid-column-start: 4;
  margin-left: -52%;
}

.file-input-preview.image{
  justify-content: center;
}

.file-input-preview.file{
  justify-content: flex-start;
  padding-top: 15px;
  padding-left: 5px;
}

.file-input-preview {
  display: flex;
  /* justify-content: center; */
  
  flex-direction: column;
  width: 100%; /* Defina a largura do indicador de entrada de arquivo */
  height: 83%; /* Defina a altura do indicador de entrada de arquivo */
  border: 2px dashed #ccc; /* Adicione uma borda tracejada ao redor do indicador */
  border-radius: 5px;
  color: #888; /* Defina a cor do texto dentro do indicador */
  font-size: 16px;
  cursor: pointer;

  /* Estilize o texto "Clique ou arraste!" */
  .small-text-archive {
    align-self: center;
    font-size: 12px;
    color: #888;
    cursor: pointer;
  }

  /* Estilize o elemento de lista (aqui, usamos uma lista não ordenada) */
  .archive-list {
    list-style-type: none; /* Remova os marcadores de lista padrão */
    padding: 0;
  }

  .square-placeholder .archive-list{
    margin-bottom: 20px;
  }

  /* Estilize cada item da lista de arquivos selecionados */
  .archive-item {
    display: inline-flex;
    justify-self: flex-start; /* Alinhe os elementos à esquerda */
    align-self: flex-start;
    /* background-color: rgba(79, 81, 127, 0.223); */
    background-color: rgba(46, 139, 87, 0.2);
    /* background-color: rgba(108, 117, 125, 0.2); */
    border-radius: 30px;
    margin-bottom: 5px;
    max-width: 95%; /* Garanta que cada item ocupe a largura completa da caixa */
    padding: 7px;
  }

  .archive-item.file-item.view {
    margin-top: 10px;
    margin-left: 10px;
    color:#007bff;
    cursor: pointer;
  }

  .archive-item.file-item.view:hover {
    text-decoration: underline;
  }

  /* Estilize o botão "Excluir" para remover arquivos da lista */
  .remove-archive {
    align-self: center;
    cursor: pointer; /* Mude o cursor ao passar o mouse sobre o botão */
    background-color: #EF5350;
    border-width: 0px;
    width: 20px; /* Largura igual à altura para criar um botão circular */
    height: 20px; /* Largura igual à altura para criar um botão circular */
    border-radius: 50%; /* Define a borda como circular */
    color: white; /* Defina a cor do botão de remoção */
    display: flex; /* Isso é opcional, mas pode ajudar a centralizar o conteúdo */
    justify-content: center; /* Isso é opcional, mas pode ajudar a centralizar o conteúdo */
    align-items: center;
    margin-left: 10px;
  }
  
  .remove-archive svg {
    font-size: 16px; /* Tamanho da fonte do botão de remoção */
  }

  .archive-indicator {
    display: flex;
    justify-content: center;
    align-self: center;
    justify-self: center;
  }

  .archive-indicator svg{
    color: #9d9b9b; /* Defina a cor do texto dentro do indicador */
    font-size: 62px;
  }
}

.file-input-preview.view{
  cursor: default;
}

.radio-options {
  display: flex;
  flex-direction: row; /* Isso faz com que as opções sejam exibidas horizontalmente */
  gap: 10px; /* Espaçamento entre as opções (opcional) */
  justify-content: flex-start;
}

/* Estilos para os labels (opcional) */
.radio-options label {
  display: inline-block;
  margin-right: 10px; /* Espaçamento entre as opções (opcional) */
}

/* * estilos para card de confirmacao de exclusao * */
.card-delete {
  overflow: hidden;
  position: fixed;
  top: 45%;
  left: 55%;
  background-color: #ffffff;
  text-align: left;
  border-radius: 0.5rem;
  max-width: 310px; 
  box-shadow: 0 30px 40px -10px rgba(0, 0, 0, 0.2), 0 20px 20px -10px rgba(0, 0, 0, 0.1), 0 -30px 40px -10px rgba(0, 0, 0, 0.1), 0 20px 20px 10px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%); /* Centraliza no meio da tela */
  opacity: 0;
  pointer-events: none; /* Impede cliques e interações quando opacidade é zero */
  transition: opacity 0.5s ease;
}


.card-delete.visible {
  opacity: 1;
  pointer-events: auto; /* Permite cliques e interações quando visível */
}

.header-delete {
  padding: 1.25rem 1rem 1rem 1rem;
  background-color: #ffffff;
}

.image-delete {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: #FEE2E2;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
}

.image-delete svg {
  color: #DC2626;
  width: 1.5rem;
  height: 1.5rem;
}

.content-delete {
  margin-top: 0.75rem;
  text-align: center;
}

.title-delete {
  color: #111827;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.message-delete {
  margin-top: 0.5rem;
  color: #6B7280;
  font-size: 0.95rem;
  line-height: 1.25rem;
}

.actions-delete {
  margin: 0.75rem 1rem;
  background-color: #F9FAFB;
}

.desactivate-delete {
  display: inline-flex;
  padding: 0.5rem 1rem;
  background-color: #dc3545;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 550;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: transparent;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.desactivate-delete:hover {
  background-color: #c82333;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.cancel-delete {
  display: inline-flex;
  margin-top: 0.75rem;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  color: #374151;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 550;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid #D1D5DB;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  transition: background-color 0.3s ease;
}

.cancel-delete:hover {
  color: white;
  background-color: #6c757d;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.extend-line {
  cursor: pointer;
  margin-left: 10px;
  margin-right: -100px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF;
  color: #869099;
  height: 60px;
  /* padding: 24px; */
}

.ft-msg {
  margin-right: 10px;
  justify-self: flex-end;
}

.icon-img {
  margin-left: 25px;
  width: 350px; /* Defina o tamanho da imagem conforme necessário */
  height: 150px;
}

.wrapper-social-ft {
  display: flex;
  justify-content: left;
  align-content: center;
}

.wrapper-social-ft .icon {
  /* margin-top: -15px; */
  /* margin-left: -15px; */
  position: relative;
  border-radius: 50%;
  padding: 15px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  cursor: pointer;
}

.href-social-ft {
  color: #869099;
}

.href-social-ft:hover {
  color: rgb(200, 193, 193);
}

/* Feedback */
.feedback-container {
  margin-top: -55px;
  height: 100vh;
  width: 100vw;
  z-index: 1; 
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../public/dist/img/an_elementos-09.png');
  background-size: 800px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: #303741;
}

.feedback-modal {
  color: white;
  width: 530px;
  background-color: rgba(255, 255, 255, 0.137);
  display: flex;
  /* margin: 5px;  */
  flex-direction: column;
  /* gap: 20px; */
  padding: 30px 20px;
  text-align: flex;
  border-radius: 10px;
}

#feedback {
  background-color: rgba(255, 255, 255, 0.348);
  border-color: rgba(255, 255, 255, 0.348);
  color: black;
  font-size: 20px;
}

#feedback::placeholder {
  color: black;
  font-size: 20px;
}

.feedback-total {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Alinhar no topo verticalmente */
  align-items: center;
  min-height: 100vh;
  background-color: #303741;
  color: #ccc;
  margin-top: -55px;
}

.img-feedback {
  display: flex;
  justify-content: center; /* Centralizar a imagem horizontalmente */
  align-items: flex-start; /* Alinhar a imagem no topo verticalmente */
  margin-top: 20px; /* Espaço entre a imagem e o topo da página */
}

.img-feedback img {
  display: block;
}

.feedback-message {
  text-align: center;
  padding: 20px;
}

.caracter-message {
  color: #93979c
}

.separator {
  border-top: 1px solid #ccc; /* Estilização da linha separadora */
  margin: 10px 0; /* Espaçamento superior e inferior da linha separadora */
}

.companies-added {
  font-size: 1.3rem; /* Tamanho de fonte ajustado */
  color: #929ba2; 
}

.companies-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%; /* Largura ajustada */
  max-height: 200px; /* Defina a altura máxima da box para evitar overflow */
  min-height: 200px; /* Defina a altura máxima da box para evitar overflow */
  overflow-y: auto; /* Permite scroll vertical se o conteúdo exceder a altura */
  border: 2px dashed #ccc; /* Adicione uma borda tracejada ao redor do indicador */
  border-radius: 5px;
  color: #888; /* Defina a cor do texto dentro do indicador */
  font-size: 16px;
  padding-left: 5px;
  padding-right: 5px; /* Adicionado padding-right para ajustar a aparência interna */
}

.company-item {
  color: #888; /* Defina a cor do texto dentro do indicador */
  font-size: 18px;
  justify-self: flex-start; /* Alinhe os elementos à esquerda */
  align-self: flex-start;
  /* max-width: 95%; Garanta que cada item ocupe a largura completa da caixa */
  margin-left: 5px;
  margin-top: 5px;
  padding: 3px;
}

/* grid para criacao de clientes*/
/* Define as áreas da grid */
.input-grid-client {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 2 colunas de largura igual */
  grid-template-rows: repeat(2, auto); /* 2 linhas automáticas */
  gap: 10px; /* Espaço entre as células */
  grid-template-areas:
    'c0 c1 c2 c6'
    'c3 c4 c5 c6'
    ;
}

.c0 {
  grid-area: c0;
}

.c1 {
  grid-area: c1;
}

.c2 {
  grid-area: c2;
}

.c3 {
  grid-area: c3;
}

.c4 {
  grid-area: c4;
}

.c5 {
  grid-area: c5;
}

.c6 {
  grid-area: c6;
}

.error-template{
  padding: 40px 15px;
  text-align: center;
  color: white;
}

.error-actions{
  margin-top: 15px;
  margin-bottom: 15px;
}

.error-actions .btn{
  margin-right: 10px;
}

/* Estilo para sobrepor a linha de informações extras */
.overlay-row {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Cor de fundo com transparência */
  z-index: 1000; /* Garante que a linha esteja acima da tabela */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilo para o conteúdo da linha de informações extras */
.extra-info-content {
  position: fixed;
  top: 20%;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7); /* Sombra para efeito de profundidade */
  width: 850px;
  height: 400px;
  max-width: 80%; /* Largura máxima */
  max-height: 200%; /* Altura máxima */
  overflow: auto; /* Adiciona rolagem quando o conteúdo for maior que a janela modal */
}

/* Estilo para o botão "X" */
.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #000; /* Cor do ícone */
}

/* estilo para olho de visualizacao de senha*/
.show-hide {
  position: relative;
  left: -40px
}

.analysis-result {
  display: grid;
  grid-template-columns: auto 1px auto ; /* Define 3 colunas: a primeira e a terceira para o conteúdo e a segunda para o separador */
  height: 600px;
  align-items: center; /* Alinha os itens verticalmente ao centro */
}

.contract {
  justify-self: start;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  border-width: 1px;
  border-style: solid;
  border-color: #000000;
  border-radius: 15px;
  height: 500px;
  width: 375px;
  margin-left: 75px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.contract-home {
  /* opacity: 0; */
  justify-self: start;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  border-width: 1px;
  border-style: solid;
  border-color: #000000;
  border-radius: 15px;
  height: 500px;
  width: 375px;
  margin-left: 75px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  transition: opacity 0.5s ease, max-height 0.5s ease;
}

.contract-home.visible {
  background: #fff;
}

.contract-home.blank {
  position: relative;
  overflow: hidden;
  background: #f6f6f6;
  border-color: #ffffff;

}

.contract-home.blank::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, #f6f6f6 25%, #e0e0e0 50%, #f6f6f6 75%);
  animation: loading 2.5s infinite;
}

@keyframes loading {
  0% {
    left: -100%;
  }
  50% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}

.contract-title{
  margin-top: 60px;
  font-weight: bold;
  max-width: 375px;
  text-decoration: underline;
}


.menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 colunas */
  grid-template-rows: repeat(3, auto); /* 3 linhas */
  gap: 20px; /* Espaçamento entre os botões */

}

.option-btn {
  width: 150px;
  height: 50px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  padding-right: 0;
  padding-left: 0;
}

.btn-0 {
  background-color: #c9e4de;
}

.btn-1 {
  background-color: #ffdca9;
}

.btn-2 {
  background-color: #dccbf0;
}

.btn-3 {
  background-color: #f9c8c8;
}

.btn-4 {
  background-color: #f2c5de;
}

.btn-5 {
  background-color: #c9e4de;
}

.option-btn-active {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  border-style: solid;
  border-width: 3px;
  border-color: #e3e3e3;
  transform: scale(1.1); /* Aumenta a escala do botão */
  transition: transform 0.3s ease; /* Suaviza a transição */
}

.page-indicator {
  font-size: 20px;
  background-color: #007bff;
  border-radius: 11px;
  color: white;
  padding: 7px 14px 7px 14px;
  margin: 0 10px 0 10px;
}

.separator {
  justify-self: center;
  width: 1px;
  height: 108%; /* Ajusta a altura do separador para cobrir toda a altura da análise */
  background-color: #1d1b1b;
  filter: blur(1.5px); /* Aplica um desfoque de 2 pixels ao separador */
}

.result-card {
  justify-self: end;
  background-color: #FFF;
  border-width: 1px;
  border-style: solid;
  border-color: #000000;
  border-radius: 15px;
  height: 550px;
  width: 425px;
  margin-right: 75px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result-title {
  margin-top: 4vh;
  width: 370px;
  max-height: 40px;
}

.result-body {
  overflow-y: auto;
  padding: 4vh;
}

.result-body-home {
  width: 380px;
  overflow-y: auto;
  overflow-x: auto;
  padding: 4vh;
}

 /* Estilos para o formulario da analise de contrato */

 .analysis-request-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, auto);
  gap: 10px; /* Espaço entre as células */
  grid-template-areas:
    'campo-analysis1 campo-analysis6'
    'campo-analysis2 campo-analysis6'
    'campo-analysis3 campo-analysis6'
    'campo-analysis4 campo-analysis6'
    ;
}

/* Associa as classes às áreas */
.campo-analysis1 {
  grid-area: campo-analysis1;
}

.campo-analysis2 {
  grid-area: campo-analysis2;
}

.campo-analysis3 {
  grid-area: campo-analysis3;
}

.campo-analysis4 {
  grid-area: campo-analysis4;
}

.campo-analysis5 {
  grid-area: campo-analysis5;
}

.campo-analysis6 {
  grid-area: campo-analysis6;
}

/* Estilos para bolas de loading */

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  width: 60px;
  display: flex;
  justify-content: space-evenly;
}

.ball {
  list-style: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #6c757d;
}
.loading-text {
  padding-bottom: 20px;
  font-size: 20px;
  color: #6c757d;
  animation: fade 2.1s ease-in-out infinite;
}

.ball:nth-child(1) {
  animation: bounce-1 2.1s ease-in-out infinite;
}


@keyframes fade {
  50% {
      opacity: 0.5;
  }
}

@keyframes bounce-1 {
  50% {
    transform: translateY(-18px);
    width: 6px;
    height: 6px;
  }
}

.ball:nth-child(2) {
  animation: bounce-3 2.1s ease-in-out 0.3s infinite;
}

@keyframes bounce-2 {
  50% {
    transform: translateY(-18px);
    width: 6px;
    height: 6px;
  }
}

.ball:nth-child(3) {
  animation: bounce-3 2.1s ease-in-out 0.6s infinite;
}

@keyframes bounce-3 {
  50% {
    transform: translateY(-18px);
    width: 6px;
    height: 6px;
  }
}


/* Estilo para tag de beta */

.beta-text {
  color: rgb(155, 155, 155); /* Altera a cor para cinza */
  margin-left: 5px; /* Adiciona um espaço à esquerda */
}

/* Estilo para home das analises */
.analysis-home {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 colunas */
  padding: auto;
  width: 100%;
}

/* Media query para telas grandes */
@media (min-width: 1500px) {
  .analysis-home {
    justify-content: center;
    width: 70%;
    margin: 0 auto;
  }
}
